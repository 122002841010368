

export default (data) => {
  let creditSum = 0;
  let normalizedGradeSum = 0;
  return [
   // {text: '3) Noten / Grades', style: 'subheader'},

    /*
    { style: 'tableExample',
      table: { widths: [50,50, '*','auto', 40,40,40],
        body: [
          [
            {text: 'Bestmögliche Note für diesen Kurs.\nHighest possible grade of the course', alignment:'center'},
            {text: 'schlechteste Note zum Bestehen des Moduls, lowest grade to pass the course', alignment:'center'},
            {text: 'Modul\nModule or Course', alignment:'center'},
            {text: 'Credits', alignment:'center'},
            {text: 'Note/Grade', alignment:'center'},
            {text: 'Bavarian Grade', alignment:'center'},
            {text: 'Credits * Grade', alignment: 'center'},
          ],
          ...data.grades.filter(x=>!!x.name).map(x => {
            const credits = parseFloat(x.ects)
            const grade = parseFloat(x.grade)

            // Find uni and best and worst grade
            if (data.bachelors == undefined) data.bachelors = []
            if (data.externalUnis == undefined) data.externalUnis = []
            let uni = data.bachelors.filter(uni=>uni.university+uni.subject == x.uni)[0]
            const externalUni = data.externalUnis.filter(uni=>uni.university+'EXTERNAL' == x.uni)[0]
            if (uni == undefined) uni = externalUni;
            const best = parseFloat(uni.bestGrade)
            const worst = parseFloat(uni.worstGrade)

            // calculate normalized grade
            let normalizedGrade = mapf(grade, best, worst, 1.0, 4.0);
            if (normalizedGrade < 1) normalizedGrade = 1;
            if (normalizedGrade > 4) normalizedGrade = 4;
            creditSum += credits;
            normalizedGradeSum += normalizedGrade * credits
            return [best, worst, x.name, credits, grade, (Math.floor(normalizedGrade*10) / 10).toFixed(1), (Math.round(normalizedGrade * credits * 10) / 10)]
          }
          ),
          [{colSpan: 3, text:'Gesamt / Total', alignment: 'right', style: 'tableHeader'}, '', '', creditSum, {colSpan: 2, text:'',},'', Math.round(normalizedGradeSum * 10)/10],
        ]
      }
    },

    {text: '4) Total Grade', style: 'subheader'},
    { style: 'tableExample',
      table: { widths: ['*',50],
        body: [
          [
            {text: 'Note gemäß dem Notensystem der TUM / Grade according to the system of TUM:', alignment:'left', style: 'tableHeader'},
            {text: (Math.floor(normalizedGradeSum/creditSum*10)/10).toFixed(1), alignment:'center', style: 'tableHeader'},
          ],
        ]
      }
    },

*/
    {text: '3) Information true and correct', style: 'subheader'},
    {text: (data.confirm ? 'I hereby declare that the information provided is true and correct.' : 'Application is invalid.')},
  ]
}