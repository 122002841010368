
import allModuleSections from '../courses'

function generateModuleTable(data, moduleSections) {
  let totalSumCredits = 0;
  let totalSumEcts = 0;
  return { style: 'tableExample', color: '#444',
      table: { widths: [120, '*', 'auto', 'auto'],
        headerRows: 1,
        body: [
          // Table header
          [
            {text: 'Bereich/Section', style: 'tableHeader', alignment: 'center'},
            {text: 'Modul / Module or Course', style: 'tableHeader', alignment: 'center'},
            {text: 'Credits', style: 'tableHeader', alignment: 'center'},
            {text: 'ECTS', style: 'tableHeader', alignment: 'center'}
          ],

          // Modules
          ...moduleSections.flatMap(moduleSection => {
            let sumCredits = 0;
            let sumEcts = 0;
            let courses = data["module_" + moduleSection.shortname]
            if (courses == undefined) courses = []
            return [
              ...courses.map((c, i) => {
                const credits = parseFloat(c.credits)
                totalSumCredits += credits
                totalSumEcts += parseFloat(c.ects)
                sumCredits += credits
                sumEcts += parseFloat(c.ects)
                return [((i == 0) ? {rowSpan:courses.length + 1, text: moduleSection.name + "\n"+ moduleSection.ects} : ''), "[" + c.id + "] " + c.name, credits, parseFloat(c.ects)];
             }),
             [moduleSection.name + "\n"+ moduleSection.ects, {colSpan: 1, alignment:'right', style:'tableHeader', text: 'Summe / Sum '}, { style:'tableHeader', text:sumCredits}, { style:'tableHeader', text:Math.round(sumEcts*10)/10}],
            ];
          }),
          
          [{colSpan: 2, alignment:'right', style:'tableHeader', text: 'Gesamt / Total '}, '',{ style:'tableHeader', text:totalSumCredits}, { style:'tableHeader', text:Math.round(totalSumEcts*10)/10}],
        ]
      }
    }
}

export default (data) => {
  return [  
    {text: '2) Curricularanalyse / Analysis of the Curriculum', style: 'subheader'},
    {text: 'a) Grundlagen der Informatik / Basics in Informatics:', style: 'subheader'},
    generateModuleTable(data, allModuleSections.filter(x=>x.section=='basic')),
    {text: 'b) Mathematische und theoretische Grundlagen / Mathematical and theoretical basics:', style: 'subheader'},
    generateModuleTable(data, allModuleSections.filter(x=>x.section=='math')),
  ]
}
                                