
export default (data) => [
 {text: 'Ihr Bachelorstudium / Your Bachelor course of study', style: 'subheader'},
 ...data.bachelors.map((bachelor) => {
   return {
      style: 'tableExample',
      table: {
              widths: [200, '*'],
        body: [
          ['Name der Hochschule/\nName of university:', bachelor.university],
          ['Studienfach / Subject:', bachelor.subject],
          ['Regelstudienzeit / Regular duration:', bachelor.duration],
          ['Insgesamt vergebene Credits/\nTotal number of credits:', bachelor.ects],
          ['Umrechnungsfaktor/\nTranslation factor:', Math.round(bachelor.translationFactor * 100) / 100],
          ['Bestmögliche note/\nBest possible grade:', bachelor.bestGrade],
          ['Schlechtmöglichste Note/\nWorst possible grade to still pass:', bachelor.worstGrade],
          ['Durschnittsnote/\nAverage grade:', bachelor.averageGrade],
          ['Durschnittsnote (Bayerische Skala)/\nAverage grade (bavarian scale):', bachelor.bavarianAverageGrade],
        ]
      }
    }
  }),
]