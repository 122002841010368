import * as React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts'

import personalInfo from './pdfsections/personalInfo'
import bachelor from './pdfsections/bachelor'
import modules from './pdfsections/modules'
import grades from './pdfsections/grades'
import styles from './pdfsections/styles'

function getDefinition(data){
  // Calculate some info
  for (const bachelor of data.bachelors) {
    bachelor.translationFactor = 60 * bachelor.duration / bachelor.ects;
  } 

  const header = [
    {text: 'Curricularanalyse / Analysis of the Curriculum', style: 'header', alignment: 'center'},
    {text: 'M. Sc. Informatics / M. Sc. Data Engineering and Analytics\n(required: Bachelor in Informatics / Computer Science or similar)', style: 'subheader', alignment: 'center'},
    {alignment: 'center', text:'This document is generated automatically and should be uploaded to TumOnline'},
  ]
  
  return {
    content: [
      ...header,
      ...personalInfo(data),
      ...bachelor(data),
      ...modules(data),
      ...grades(data),
    ],
    info: {
      jsonData: JSON.stringify(data),
      title: 'CurriculumAnalysis'
    },
    ...styles,
  };
}

export default async function pdfview(data) {
  const {vfs} = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;
  const pdfDocGenerator = pdfMake.createPdf(getDefinition(data));
  pdfDocGenerator.open()
}