export default
{
 "type": "multipletext",
 "name": "general",
 "minWidth": "400px",
 "title": "Personal Information",
 "items": [
  {
   "name": "name",
   "isRequired": true,
   "title": "Name"
  },
  {
   "name": "firstname",
   "isRequired": true,
   "title": "First name"
  },
  {
   "name": "birthdate",
   "isRequired": true,
   "placeHolder": "e.g. 31.12.1999",
   "title": "Date of birth",
  },
  {
   "name": "nation",
   "isRequired": true,
   "title": "Nationality"
  },
  {
   "name": "id",
   "isRequired": true,
   "title": "Applicant Number (TUMonline)",
   "placeHolder": "2-01234567",
   "requiredErrorText": "Invalid applicant number",
     "validators": [
      {
       "type": "regex",
       "text": "Invalid applicant number",
       "regex": "^2-[0-9]{8}$"
      }
     ]
  }
 ]
}