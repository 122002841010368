export default (data) => [
  {text: '1) Persönliche Daten / Personal Data', style: 'subheader'},
  { style: 'tableExample',
    table: { widths: [200, '*'],
      body: [
        ['Name/Name', data.general.name],
        ['Vorname / First name:', data.general.firstname],
        ['Geburtsdatum / Date of birth:', data.general.birthdate],
        ['Staatsangehörigkeit / Nationality:', data.general.nation],
        ['Bewerbernummer/\nApplicant Number (TumOnline):', data.general.id],
      ]
    }
  },
]