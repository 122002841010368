export default (unis)=>[



  /*{
    "type": "panel",
    "name": "moreInfo",
    "state": "expanded",
    "title": "Analysis of the Curriculum",
    "description": `List your best examination results up to 120 ECTS or 2/3 of the
                    examinations necessary for your study program (courses evaluated with 'passed' can 
                    only be used when there are no regularly graded courses left)! 
                    You can list courses that you have already listed on page 2 if needed.`,
    "elements": [
      {
            "type": "dropdown",
            "name": "gradeUni",
            "title": "University",
            "isRequired": true,
            "hasNone": false,
            "choices": unis
        },
       {
        "type": "matrixdynamic",
        "name": "grades",
        "title": "You can only indicate courses/modules from one(!) Bachelor's program (which you will finish and which qualifies for the Master's program).",
        "rowCount": 1,
        "addRowText": 'Add course',
        "columns": [
         {
             "cellType": "text",
             "name": "name",
             "isRequired": true,
             "title": "Course/Module Name",
             "placeHolder": "e.g. Introduction to Computer Science"
          },
          {
             "cellType": "text",
             "name": "ects",
             "isRequired": true,
             "title": "Number of Credits",
             "placeHolder": "e.g. 5",
             totalType: "sum",
             totalFormat: "Total credits: {0}",
          },
          {
             "cellType": "text",
             "name": "grade",
             "isRequired": true,
             "title": "Grade",
             "placeHolder": "e.g. 1.3"
          },
       
 
        ]
      }
   ]
  },*/

  {
       "type": "boolean",
       "name": "confirm",
       "title": "I hereby declare that all information provided is true and correct.",
       "isRequired": true,
  }
]