import * as Survey from "survey-react";
import { FunctionFactory } from "survey-react";
import "survey-react/survey.css";
import PDF from './PDF';

import personalInfo from './questions/personalInfo'
import bachelor from './questions/bachelor'
import modules from './questions/modules'
import grades from './questions/grades'

// Change the main color to tum blue
let defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
Survey.StylesManager.ThemeCss[".sv_main.sv_default_css input[type='text']::placeholder"] = "color: #888;";
defaultThemeColors["$main-color"] = "rgb(48, 112, 179)";
defaultThemeColors["$main-hover-color"] = "rgb(38, 92, 129)";
defaultThemeColors["$text-color"] = "#555";
defaultThemeColors["$text-input-color"] = "#666";
// defaultThemeColors["$header-color"] = "#fff";

// defaultThemeColors["$header-background-color"] = "#fff";
// defaultThemeColors["$body-container-background-color"] = "#fff"
 Survey.StylesManager.applyTheme();


// Form setup

const unis = []


const page1 = {
  name: "page1",
  elements: [
    { type: "html", html: `
      <h1>M. Sc. Informatics / M. Sc. Data Engineering and Analytics</h1>
      <div style="font-size: 16px;">
      Welcome to the application process of TUM.<p><strong>Apply in TUMonline first in order to get your applicant number. Then fill this form. </strong></p>This form will guide you through the steps of the curricular analysis. First, enter your name, personal information, and universities you attended on this page. Entering the courses for the curricular analysis will be done through the following pages. On the next two pages, please enter the basic modules or fundamental courses of your study program that cover the given topics (see links to module description; all other courses will not be counted here)! Then enter your credits. They are automatically transferred to the European Credit Transfer System (ECTS)!
      </div>

      `
     
    }, personalInfo, ...bachelor,
  ],
}

const page2 = {
  name: "page2",
  elements: [
    { type: "html", html: `
      <h1>Informatics / Data Engineeing and Analytics</h1>
      <div style="font-size: 16px;"><p><strong>If your Bachelor's degree program is Informatics at TUM you can skip this part.</strong></p>
      On this page, we conduct the curricular analysis to check the equivalence of your Bachelor's study program with TUM's B.Sc. Informatics. Therefore, please enter the equivalent modules of your program for each of the given TUM modul areas (Introduction to Informatics, Databases, etc.). To choose equivalent modules, please read the descriptions of TUM's modules (linked) and compare them to the description of your program's modules. Then, enter all your equivalent modules for the corresponding module area. The credits of your modules are automatically transferred to the European Credit Transfer System (ECTS)!
      <p><strong>If a module covers content of multiple TUM modules you can use it multiple times and provide details in the description. However, you have to split the credits - they are only counted once.</strong></p>
      </div>
      `
    }, ...modules.form([]),
    {
     "type": "boolean",
     "name": "question1",
     "title": "I confirm that I passed all courses above! ",
     "isRequired": true,
     "requiredErrorText": "All courses must be passed.",
     "validators": [
      {
       "type": "expression",
       "expression": "{question1} = true",
       "text": "All courses must be passed."
      }
      ]
    }
  ],
}

const page3 = {
  name: "page3",
  elements: [
    { type: "html", html: `
      <h1>Confirmation</h1>
      `
    }, ...grades(unis)
  ],
}

//      List your best examination results up to 120 ECTS or 2/3 of the examinations necessary for your study program (courses evaluated with 'passed' can only be used when there are no regularly graded courses left)! You can list courses that you have already listed on page 2 if needed.

let ectsMapping = []

function creditsToEcts(credits) {
if (!credits[0]) return 0;
 credits=credits[0]

let uniForCalculation = ectsMapping[0];
  if (credits.uni) {
     for (const uni of ectsMapping) {
       if (uni.university + uni.subject === credits.uni) {
         // found program
         uniForCalculation = uni;
       }
     }
  }


  if (parseFloat(credits.credits) > 0) {

    const translationFactor = 60 * parseFloat(uniForCalculation.duration) / parseFloat(uniForCalculation.ects);
    return (Math.round(parseFloat(credits.credits) * (translationFactor) * 10) / 10)
  }
  return 0;
}

function mapf(x, in_min, in_max, out_min, out_max) {
  let y = (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;


  return y
}
function toBavarian(row) {
  const bestGrade = parseFloat(row[0]?.bestGrade)
  const worstGrade = parseFloat(row[0]?.worstGrade)
  const grade = parseFloat(row[0]?.averageGrade)
  if (bestGrade && worstGrade && grade) {
    const outGrade = mapf(grade, bestGrade, worstGrade, 1.0, 4.0)
    if (outGrade < 1) outGrade = 1;
    if (outGrade > 4) outGrade = 4;
    const outStr = `${Math.round(outGrade * 10)  / 10}`
    return outStr;
  }
  return 'Please enter "best grade", "worst grade" and "average grade".';
}
FunctionFactory.Instance.register("toBavarian", toBavarian);
FunctionFactory.Instance.register("creditsToEcts", creditsToEcts);


export default function App(props) {
  var model = new Survey.Model({ showQuestionNumbers: "off", pages: [page1, page2, page3]});
  model.data = props.data
  var item = new Survey.ItemValue("x", "y");

  const onChange = ()=>{
    unis.length = 0;

    ectsMapping = model.data["bachelors"] ? model.data["bachelors"]:[]


    const newUnis = [
      ...(model.data["bachelors"] ? model.data["bachelors"].map(x=>new Survey.ItemValue(x.university+x.subject, x.university + " (" +x.subject + ")")):[]),
      ...(model.data["externalUnis"] ? model.data["externalUnis"].map(x=>new Survey.ItemValue(x.university+"EXTERNAL", x.university + " (external)")):[])
    ]
    for (const uni of newUnis) {
      unis.push(uni);
    }
    
     for (const moduleId of modules.moduleIds) {
       model.getQuestionByName(moduleId).choices = unis
     }
     //model.getQuestionByName("grades").choices = unis
     //model.getQuestionByName("gradeUni").choices = unis
  }

   const onChangeGradeUni = ()=>{
    console.log(model.data["gradeUni"], unis)
  }

onChange()
  const bachelor = model.getQuestionByName("bachelors")
  const externalUnis = model.getQuestionByName("externalUnis")
  //const gradeUni = model.getQuestionByName("gradeUni")

  bachelor.onValueChanged = onChange
  externalUnis.onValueChanged = onChange
  //gradeUni.onValueChanged = onChangeGradeUni

  return (
    <Survey.Survey model={model} showCompletedPage = {true}
     onComplete = {(json)=>{
       console.log(model);
       PDF(model.data)
       console.log(JSON.stringify(model.data))
     }}/>
  );
}
