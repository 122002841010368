import moduleSections from '../courses'

function generateModuleForm(x, unis) {
    return {
        type: "panel",
        name: "modulepanel",
        elements:[
        {
            type: "html",
            html: "<h2>"+x.name+"</h2>" + "<h4>"+x.ects+"</h4>" + x.description,
        },
      
        {
        "type": "matrixdynamic",
        "name": "module_" + x.shortname,
        "title": ' ',
        "titleLocation": "hidden",
        "addRowText": "Add course",
        "rowCount": 0,
        "columns": [
            {
                "cellType": "dropdown",
                "name": "uni",
                "title": "University",
                "isRequired": true,
                "colCount": 0,
                "hasNone": false,
                "choices": unis
            },
            {
            "cellType": "text",
            "name": "name",
            "title": "Module or Course Name",
            "isRequired": true,
            "placeHolder": "e.g. Introduction to Computer Science"
            },
            {
            "cellType": "text",
            "name": "credits",
            "title": "Number of Credits",
            "isRequired": true,
            "placeHolder": "e.g. 5",
            totalType: "sum",
            "totalFormat": "Sum of credits {0}"
            },

            {
          name: "ects",
          title: "ECTS",
          cellType: "expression",
          expression: "creditsToEcts({row})",
          totalType: "sum",
          totalFormat: "Sum of ECTS: {0}"
        }
           
        ],
        "detailPanelMode": "underRowSingle",
        "detailElements": [


              {
            "type": "text",
            "name": "id",
            "isRequired": true,
            "title": "Course ID",
            "placeHolder": "e.g. IN0001"
            },
            {
                "type": "comment",
                "name": "content",
                "isRequired": true,
                "title": "Course Content (as stated in your module handbook) + Link to Course Website (if available)",
                "placeHolder": "e.g. The module IN0001 is concerned with topics such as: Introduction, Basic notions: Problem, algorithm, program, Imperative programming constructs, Syntax and semantics, Syntax of programming languages: regular expressions and contextfree grammers, Semantics of programs: control-flow graphs, Basic data structures I, Numbers, strings, arrays, Insertion sort, Recursion, Binary search, Patterns of recursion, Basic data structures II,  Objects, classes, methods, Lists, stacks, queues, Object-oriented programming, Inheritance, Abstract classes and interfaces, Polymorphism, Programming in the large (perspectives), Concurrency and Threads"
            },

        ]
    },

   

    ]}
}


export default {
    form: (unis) => {
        const modulesBasic = moduleSections.filter(x => x.section == 'basic').map(x=>generateModuleForm(x,unis))
        const modulesMath = moduleSections.filter(x => x.section == 'math').map(x=>generateModuleForm(x,unis))

        return[
            { "type": "html", "html": `<h1>Basics in Informatics</h1>`}, ...modulesBasic,
            { "type": "html", "html": `<h1>Mathematical and theoretical basics</h1>`}, ...modulesMath,
        ]
    }, 
    moduleIds: moduleSections.map(x=>"module_"+x.shortname)
}
                                