
export default [

    {
    "type": "panel",
    "name": "bachelorCourses",
    "state": "expanded",
    "title": "Bachelor Program",
    "elements": [
        {
            "type": "matrixdynamic",
            "name": "bachelors",
            "title": 'Enter your study program(s) and associated universities: Click "Add study program" to add an additional entry. Please enter the full name of your university, e.g., Technische Universität München instead of TU München, TU Munich or TUM. Then, open the drop-down menu using the blue button on the left to enter the details of your program.',
            "rowCount": 1,
            "addRowText": "Add study program",
            columns: [
            {
                        cellType: "text",
                     "name": "university",
                     "isRequired": true,
                     "title": "University"
                    },
                     {
                        cellType: "text",
                     "name": "subject",
                     "isRequired": true,
                     "title": "Subject"
                    },
             ],
            "detailPanelMode": "underRowSingle",
            "detailElements": [
                    
                   
                    {
                        type: "text",
                     "name": "duration",
                     "isRequired": true,
                     "title": "Regular duration in years",
                     "validators": [{ "type": "numeric"}]
                    },
                    {
                        type: "text",
                     "name": "ects",
                     "isRequired": true,
                     "title": "Total number of credits of the study program",
                     "validators": [{ "type": "numeric"}]
                    },
                    {
                        type: "text",
                     "name": "bestGrade",
                     "isRequired": true,
                     "title": "Best possible grade",
                     "placeHolder": "e.g. 1.0",
                     "validators": [{ "type": "numeric"}]
                    },
               
                    {
                        type: "text",
                     "name": "worstGrade",
                     "isRequired": true,
                     "title": "Worst possible grade to still pass",
                     "placeHolder": "e.g. 4.0",
                     "validators": [{ "type": "numeric"}]
                    },

                         {
                        type: "text",
                     "name": "averageGrade",
                     "isRequired": true,
                     "title": "Your grade average (as stated in your transcript of records)",
                     "placeHolder": "e.g. 1.4",
                     "validators": [{ "type": "numeric"}]
                    },


                    {
                        type: "expression",
                     "name": "bavarianAverageGrade",
                     "title": "Your grade average (bavarian scale)",
                     expression: "toBavarian({row})"
                    },

                    

            ]
        }
    ]
},


    {
    "type": "panel",
    "name": "exchangeUnis",
    "state": "expanded",
    "title": "External universities (semesters abroad, additional modules)",
    "elements": [
        {
            "type": "matrixdynamic",
            "name": "externalUnis",
            "addRowText": "Add external university",
            "title": "Add additional universities from which you have taken modules, e.g., for student exchange/semester abroad.",
            "rowCount": 0,
            columns: [
            {
                        cellType: "text",
                     "name": "university",
                     "isRequired": true,
                     "title": "University"
                    },
             ],
           
        }]}
    ]