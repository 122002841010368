export default {
styles: {
    header: {
      fontSize: 18,
      bold: true,
      margin: [0, 0, 0, 10]
    },
    subheader: {
      fontSize: 14,
      bold: true,
      margin: [0, 10, 0, 5]
    },
    tableExample: {
        fontSize: 9,
      margin: [0, 5, 0, 15]
    },
    tableHeader: {
         fontSize: 9,
      bold: true,
      color: 'black'
    }
  },
  defaultStyle: {
    // alignment: 'justify'
  }
}