
export default [
  {section: 'basic', name: 'Introduction to Informatics Object-oriented Programming', shortname: 'INFO1', ects:'6 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=455630&pOrgNr=14189">IN0001</a>', placeholder0: 'IN0001', placeholder1: 'Introduction to'},
  {section: 'basic', name: 'Fundamentals of Programming (Exercises & Laboratory)', shortname: 'PGDP', ects:'6 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=452806&pOrgNr=14189">IN0002</a>', placeholder0: 'IN0002', placeholder1: 'Practical Programming'},
  {section: 'basic', name: 'Computer Architecture: Organization and Technology', shortname: 'ERA', ects:'8 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=452812&pOrgNr=14189">IN0004</a>', placeholder0: 'IN0004', placeholder1: 'Computer Architecture'},
  {section: 'basic', name: 'Laboratory - Computer Architecture', shortname: 'ERAPRA', ects:'8 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=452814&pOrgNr=14189">IN0005</a>', placeholder0: 'IN0005', placeholder1: 'Practical Computer Architecture'},
  {section: 'basic', name: 'IT Security', shortname: 'ITSEC', ects:'5 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/pl/ui/$ctx;design=pl;header=max;lang=de/WBMODHB.wbShowMHBReadOnly?pKnotenNr=2911725&pOrgNr=14189">IN0042</a>', placeholder0: 'IN0042', placeholder1: 'IT Security'},
  {section: 'basic', name: 'Software Engineering', shortname: 'SE', ects:'6 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=452816&pOrgNr=14189">IN0006</a>', placeholder0: 'IN0006', placeholder1: 'Software Engineering'},
  {section: 'basic', name: 'Algorithms and Data Structures', shortname: 'GAD', ects:'6 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=452818&pOrgNr=14189">IN0007</a>', placeholder0: 'IN0007', placeholder1: 'Algorithms'},
  {section: 'basic', name: 'Databases', shortname: 'DB', ects:'6 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=452820&pOrgNr=14189">IN0008</a>', placeholder0: 'IN0008', placeholder1: 'Databases'},
  {section: 'basic', name: 'Operating Systems and System Software', shortname: 'OS', ects:'6 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=452822&pOrgNr=14189">IN0009</a>', placeholder0: 'IN0009', placeholder1: 'Operating Systems'},
  {section: 'basic', name: 'Computer Networks, Distributed Systems', shortname: 'GRNVS', ects:'6 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=458318&pOrgNr=14189">IN0010</a>', placeholder0: 'IN0010', placeholder1: 'Computer Networks'},
  {section: 'math', name: 'Theory of Computation', shortname: 'THEO', ects:'6 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=454045&pOrgNr=14189">IN0011</a>', placeholder0: 'IN0011', placeholder1: 'Theory of Computation'},
  {section: 'math', name: 'Functional Programming and Verification', shortname: 'INFO2', ects:'5 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=1458914&pOrgNr=14189">IN0003</a>', placeholder0: 'IN0003', placeholder1: 'Functional Programming'},
  {section: 'math', name: 'Discrete Structures', shortname: 'DS', ects:'8 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=454051&pOrgNr=14189">IN0015</a>', placeholder0: 'IN0015', placeholder1: 'Discrete Structures'},
  {section: 'math', name: 'Linear Algebra for Informatics', shortname: 'LINA', ects:'8 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=476720&pOrgNr=14178">MA0901</a>', placeholder0: 'MA0901', placeholder1: 'Linear Algebra'},
  {section: 'math', name: 'Calculus for Informatics', shortname: 'ANA', ects:'8 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=476718&pOrgNr=14178">MA0902</a>', placeholder0: 'MA0902', placeholder1: 'Calculus'},
  {section: 'math', name: 'Discrete Probability Theory', shortname: 'DWT', ects:'6 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=454053&pOrgNr=14189">IN0018</a>', placeholder0: 'IN0018', placeholder1: 'Discrete Probability'},
  {section: 'math', name: 'Numerical Programming', shortname: 'NUMPROG', ects:'6 ECTS', description: 'Courses should be equivalent to TUM course: <a target="_blank" href="https://campus.tum.de/tumonline/WBMODHB.wbShowMHBReadOnly?pKnotenNr=454055&pOrgNr=14189">IN0019</a>', placeholder0: 'IN0019', placeholder1: 'Numerical Programming'},
]
